import React, {createRef, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";
import IntegrationWizardStepClientNotificationPreview from "./IntegrationWizardStepClientNotificationPreview.jsx"
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import NotificationTopPng from '../../Assets/NotificationTop.png';
import NotificationBottomPng from '../../Assets/NotificationBottom.png';

const IntegrationWizardStepClientNotification = (props) => {

  const [mRepainter, SetRepainter] = useState(false)
  const mDummyRef = createRef()

  const ReplaceFieldTypes = () => {
      let NewBody = [];

      for (let i = 0; i < props.IntegrationSettings.NotificationTemplate.Body.length; i++) 
      {
        let bodyLine = props.IntegrationSettings.NotificationTemplate.Body[i]

        if (bodyLine.includes("%(Name)"))
        {
            const Field = props.IntegrationSettings.DatasourceFields.filter(field => field.FieldType === "Name")
            if (Field.length > 0) 
            {
              bodyLine = bodyLine.replace("%(Name)","$(" + Field[0].FieldLabel + ")")
            }
            else 
            {
              bodyLine = bodyLine.replace("%(Name)","") 
            }           
        }

        if (bodyLine.includes("%(Organization)"))
        {
          const Field = props.IntegrationSettings.DatasourceFields.filter(field => field.FieldType === "Organization")
          if (Field.length > 0) 
          {
            bodyLine = bodyLine.replace("%(Organization)","$(" + Field[0].FieldLabel + ")")
          }
          else 
          {
            bodyLine = bodyLine.replace("%(Organization)","") 
          }  
        }    
        
        NewBody.push(bodyLine)
      } 

      props.IntegrationSettings.NotificationTemplate.Body = NewBody
    } 
    
    const ReplaceFields = (aLine) => {

      let bodyLine = aLine

      bodyLine = bodyLine.replace("#(Caller number)","0123456789")
      bodyLine = bodyLine.replace("#(Caller name)","John Doe")
      bodyLine = bodyLine.replace("#(Device number)","100")
      bodyLine = bodyLine.replace("#(Device name)","Employee 100")
      bodyLine = bodyLine.replace("#(Ddi number)","100")
      bodyLine = bodyLine.replace("#(Ddi name)","Ddi 100")
      bodyLine = bodyLine.replace("#(Application name)",props.IntegrationSettings.ApplicationName)

      {props.IntegrationSettings.DatasourceFields.map((field) => 
        bodyLine = bodyLine.replace("$(" + field.FieldLabel + ")",field.DefaultTestValue)
        )}      
      
      return bodyLine
    }

    const AddBodyLine = () => {
      props.IntegrationSettings.NotificationTemplate.Body.push("")
      SetRepainter(!mRepainter)
    }

    const AddBodyVariable = (aValue, aIndex) => {
      props.IntegrationSettings.NotificationTemplate.Body[aIndex] += aValue
      SetRepainter(!mRepainter)
    }

    const DeleteBodyLine = (aIndex) => {
      props.IntegrationSettings.NotificationTemplate.Body.splice(aIndex, 1)
      SetRepainter(!mRepainter)
    }

    const UpdateBody = (aNewValue, aIndex) => {
      props.IntegrationSettings.NotificationTemplate.Body[aIndex] = aNewValue
      SetRepainter(!mRepainter)
    }

    const UpdateHeader = (aNewValue) => {
      props.IntegrationSettings.NotificationTemplate.Header = aNewValue
      SetRepainter(!mRepainter)
    }
    
    ReplaceFieldTypes()

    const BrandingColorLight = sessionStorage.getItem("BrandingColorLight")
    
    return (
        <div>
            <IntegrationWizardStepHeader Header={Translate("Configure your call notification")}/>

            {props.StepWidth > 900 ?

            <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td width="533" valign="top">

                <div className="wizardstepnotificationsectionheader">{Translate("Preview incoming call")}</div>
                <br/>

                <IntegrationWizardStepClientNotificationPreview IntegrationSettings={props.IntegrationSettings}/>

              </td>
              <td width="17"></td>
              <td width="*">

                <div className="wizardstepnotificationsectionheader">{Translate("Configure incoming call")}</div>
                <br/>

                <table width="100%" cellPadding="20" cellSpacing="0">
                <tbody>
                <tr>
                    <td className="wizardoutercell" style={{ backgroundColor: BrandingColorLight}}>
                      <div className="wizardfieldname">Header</div>
                      <span className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.IntegrationSettings.NotificationTemplate.Header} onChange={evt => UpdateHeader(evt.target.value)} className="wizardinputfield"/></span>                 
                    
                      {props.IntegrationSettings.NotificationTemplate.Body.map((line,index) =>
                        <span key={index}> 
                          <br clear="all"/><br/>
                          <div className="wizardfieldname">Body line {index+1}</div>
                          <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={line} onChange={evt => UpdateBody(evt.target.value, index)} className="wizardinputfield"/></div> 
                          <span className="wizardfieldvalue">

                          <Popup trigger={<Button variant="primary" id={"recognitionpopup" + index}>+ Add variable</Button>} arrow={false} position="bottom left" closeOnDocumentClick={true}>
                          <div className="wizardsteppopupitemheader">-- Recognition fields --</div>
                          {props.IntegrationSettings.DatasourceFields.map((field,findex) => 
                            <div key={findex} onClick={() => { AddBodyVariable("$(" + field.FieldLabel + ")", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"$(" + field.FieldLabel + ")"}</div>
                          )}
                          <div className="wizardsteppopupitemheader"><br/>-- Call fields --</div>
                          <div onClick={() => { AddBodyVariable("#(Caller number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Caller number)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Caller name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Caller name)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Device number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Device number)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Device name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Device name)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Ddi number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Ddi number)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Ddi name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Ddi name)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Application name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Application name)"}</div>

                          </Popup>

                            &nbsp; 

                            <Button variant="dark" onClick={() => DeleteBodyLine(index)}>Remove this line</Button></span>                 
                        </span>
                      )}
                    </td>
                </tr>
                </tbody>
                </table>

                <br/>
                {props.IntegrationSettings.NotificationTemplate.Body.length < 4 ? <Button variant="dark" onClick={() => AddBodyLine()}>+ Add line</Button> : ""}

              </td>
            </tr>
            </tbody>
            </table> 
            
            :

            <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td width="*" valign="top">

                <div className="wizardstepnotificationsectionheadersmall">{Translate("Preview")}</div>

                <IntegrationWizardStepClientNotificationPreview IntegrationSettings={props.IntegrationSettings}/>

              </td>
              <td width="*"/>
            </tr>
            <tr height="12"/>
            <tr>
              <td colSpan="2">

                <table width="100%" cellPadding="20" cellSpacing="0">
                <tbody>
                <tr>
                    <td className="wizardoutercell">
                      <div className="wizardfieldname">Header</div>
                      <span className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.IntegrationSettings.NotificationTemplate.Header} onChange={evt => UpdateHeader(evt.target.value)} className="wizardinputfield"/></span>                 
                    
                      {props.IntegrationSettings.NotificationTemplate.Body.map((line,index) =>
                        <span key={index}> 
                          <br clear="all"/><br/>
                          <div className="wizardfieldname">Body line {index+1}</div>
                          <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={line} onChange={evt => UpdateBody(evt.target.value, index)} className="wizardinputfield"/></div> 
                          <span className="wizardfieldvalue">

                          <Popup trigger={<Button variant="primary" id={"recognitionpopup" + index}>+ Add variable</Button>} arrow={false} position="bottom left" closeOnDocumentClick={true}>
                          <div className="wizardsteppopupitemheader">-- Recognition fields --</div>
                          {props.IntegrationSettings.DatasourceFields.map((field,findex) => 
                            <div key={findex} onClick={() => { AddBodyVariable("$(" + field.FieldLabel + ")", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"$(" + field.FieldLabel + ")"}</div>
                          )}
                          <div className="wizardsteppopupitemheader"><br/>-- Call fields --</div>
                          <div onClick={() => { AddBodyVariable("#(Caller number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Caller number)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Caller name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Caller name)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Device number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Device number)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Device name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Device name)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Ddi number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Ddi number)"}</div>
                          <div onClick={() => { AddBodyVariable("#(Ddi name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardsteppopupitem">{"#(Ddi name)"}</div>

                          </Popup>

                            &nbsp; 

                            <Button variant="dark" onClick={() => DeleteBodyLine(index)}>Remove this line</Button></span>                 
                        </span>
                      )}
                    </td>
                </tr>
                </tbody>
                </table>

                <br/>
                {props.IntegrationSettings.NotificationTemplate.Body.length < 4 ? <Button variant="dark" onClick={() => AddBodyLine()}>+ Add line</Button> : ""}

              </td>
            </tr>
            </tbody>
            </table> 
            }
        </div>
    )
}

IntegrationWizardStepClientNotification.propTypes = {
    IntegrationSettings: PropTypes.object,
    PredefinedHeader: PropTypes.string,
    PredefinedBody: PropTypes.array,
    StepWidth: PropTypes.number
}

export default IntegrationWizardStepClientNotification